import { FC, useEffect, useState } from "react";

import { KTSVG } from "../../../../../_metronic/helpers";
import { setcallApifortodofilter, storeCapacityCompanyIds, storeCapacityDepartmentIds, storeCapacityPeopleIds, storeCapacityProjectIds, storeCapacityRoleIds, storeCapacitycontractcategoryids, storeCapacitylineOfBusinessIds, storeCapacityprioritiesids, storeCapacityprojectLeadsids, storeCapacityqueuesids, storeCapacityskillids, storeCapacityslasids, storeCapacitytaskCategoryids, storeCapacityticketCategoryids, storeCapacityticketTypeids, storeClearAllFilter, storeLocationIds, storeProjectLead } from "../../../../counterSlice";
import { useAppDispatch, useAppSelector } from "../../../../hook";
import { CapacityDropDown } from "./CapacityDropDown";

type Props = {
  filterCapacityArray?: any
  general_settings?:any
  toggleDropdown?:any
  openDropdown?:any
  setOpenDropdown?:any
}
const FilterCapacity: FC<Props> = ({ filterCapacityArray, general_settings,toggleDropdown, openDropdown, setOpenDropdown }) => {
  
  const dispatch = useAppDispatch()
  const count = useAppSelector((state) => state.counter);
  const [currentClick, setcurrentClick] = useState('');
  const [showproject, setshowproject] = useState('d-none');
  const [showrole, setshowrole] = useState('d-none');
  const [showpeople, setshowpeople] = useState('d-none');
  const [showdepartment, setshowdepartment] = useState('d-none');
  
  const [showcompany, setshowcompany] = useState('d-none');
  const [Projectid, setProjectid] = useState<any>([]);
  const [Roleid, setRoleid] = useState<any>([]);
  const [ProjectName, setProjectName] = useState<any>([]);
  const [RoleName, setRoleName] = useState<any>([]);
  const [Peopletid, setPeopletid] = useState<any>(Array);
  const [PeopletName, setPeopletName] = useState<any>([]);
  const [Departmentid, setDepartmentid] = useState<any>(Array);
  const [DepartmentName, setDepartmentName] = useState<any>([]);
  const [Companyid, setCompanyid] = useState<any>(Array);
  const [CompanyName, setCompanyName] = useState<any>([]);
  const [projectinputText, setprojectinputText] = useState("");
  const [roleinputText, setroleinputText] = useState("");
  const [peopleinputText, setpeopleinputText] = useState("");
  const [departmentinputText, setdepartmentinputText] = useState("");
  const [companyinputText, setcompanyinputText] = useState("");
  //project lead
  const [showprojectlead, setshowprojectlead] = useState('d-none');
  const [projectleadinputText, setprojectleadinputText] = useState("");
  const [projectleadid, setprojectleadid] = useState<any>(Array);
  const [projectleadName, setprojectleadName] = useState<any>([]);
  //queues
  const [showqueues, setshowqueues] = useState('d-none');
  const [queuesinputText, setqueuesinputText] = useState("");
  const [queuesid, setqueuesid] = useState<any>(Array);
  const [queuesName, setqueuesName] = useState<any>([]);
  //priorities
  const [showpriorities, setshowpriorities] = useState('d-none');
  const [prioritiesinputText, setprioritiesinputText] = useState("");
  const [prioritiesid, setprioritiesid] = useState<any>(Array);
  const [prioritiesName, setprioritiesName] = useState<any>([]);
  //slas
  const [showslas, setshowslas] = useState('d-none');
  const [slasinputText, setslasinputText] = useState("");
  const [slasid, setslasid] = useState<any>(Array);
  const [slasName, setslasName] = useState<any>([]);
  //ticketcategory
  const [showticketcategory, setshowticketcategory] = useState('d-none');
  const [ticketcategoryinputText, setticketcategoryinputText] = useState("");
  const [ticketcategoryid, setticketcategoryid] = useState<any>(Array);
  const [ticketcategoryName, setticketcategoryName] = useState<any>([]);
  //tickettype
  const [showtickettype, setshowtickettype] = useState('d-none');
  const [tickettypeinputText, settickettypeinputText] = useState("");
  const [tickettypeid, settickettypeid] = useState<any>(Array);
  const [tickettypeName, settickettypeName] = useState<any>([]);
  //taskcategory
  const [showtaskcategory, setshowtaskcategory] = useState('d-none');
  const [taskcategoryinputText, settaskcategoryinputText] = useState("");
  const [taskcategoryid, settaskcategoryid] = useState<any>(Array);
  const [taskcategoryName, settaskcategoryName] = useState<any>([]);
  //contractcategory
  const [showcontractcategory, setshowcontractcategory] = useState('d-none');
  const [contractcategoryinputText, setcontractcategoryinputText] = useState("");
  const [contractcategoryid, setcontractcategoryid] = useState<any>(Array);
  const [contractcategoryName, setcontractcategoryName] = useState<any>([]);
  //skills
  const [showskill, setshowskill] = useState('d-none');
  const [skillinputText, setskillinputText] = useState("");
  const [Skillid, setSkillid] = useState<any>(Array);
  const [SkillName, setSkillName] = useState<any>([]);

  //line of business
  const [showlineOfBusiness, setshowlineOfBusiness] = useState('d-none');
  const [lineOfBusinessinputText, setlineOfBusinessinputText] = useState("");
  const [lineOfBusinessid, setlineOfBusinessid] = useState<any>(Array);
  const [lineOfBusinessName, setlineOfBusinessName] = useState<any>([]);

  const hideSelection = (e: any) => {
    dispatch(setcallApifortodofilter(true))
    let ar: any = [];
    if (e.target.value === 'cProject') {
      setProjectid([]);
      setProjectName([]);
      dispatch(storeCapacityProjectIds(ar))
      setshowproject('d-none');
      setprojectinputText("");
    }
    else if (e.target.value === 'cRole') {
      setRoleid([]);
      setRoleName([]);
      dispatch(storeCapacityRoleIds(ar))
      setshowrole('d-none');
      setroleinputText("");
    }
    else if (e.target.value === 'cPeople') {
      setshowpeople('d-none');
      setPeopletid(ar);
      setPeopletName(ar);
      dispatch(storeCapacityPeopleIds(ar))
      setpeopleinputText("");
    } else if (e.target.value === 'cDepartment') {
      setDepartmentid(ar);
      setDepartmentName(ar);
      dispatch(storeCapacityDepartmentIds(ar))
      setshowdepartment('d-none');
      setdepartmentinputText("");
    } else if (e.target.value === 'cCompany') {
      setCompanyid(ar);
      setCompanyName(ar);
      dispatch(storeCapacityCompanyIds(ar))
      setshowcompany('d-none');
      setcompanyinputText("");
    } else if (e.target.value === 'cprojectlead') {
      setprojectleadid(ar);
      setprojectleadName(ar);
      dispatch(storeCapacityprojectLeadsids(ar))
      setshowprojectlead('d-none');
      setprojectleadinputText("");
    } else if (e.target.value === 'cqueues') {
      setqueuesid(ar);
      setqueuesName(ar);
      dispatch(storeCapacityqueuesids(ar))
      setshowqueues('d-none');
      setqueuesinputText("");
    } else if (e.target.value === 'cPriorities') {
      setprioritiesid(ar);
      setprioritiesName(ar);
      dispatch(storeCapacityprioritiesids(ar))
      setshowpriorities('d-none');
      setprioritiesinputText("");
    } else if (e.target.value === 'cSLAs') {
      setslasid(ar);
      setslasName(ar);
      dispatch(storeCapacityslasids(ar))
      setshowslas('d-none');
      setslasinputText("");
    } else if (e.target.value === 'cTicket category') {
      setticketcategoryid(ar);
      setticketcategoryName(ar);
      dispatch(storeCapacityticketCategoryids(ar))
      setshowticketcategory('d-none');
      setticketcategoryinputText("");
    } else if (e.target.value === 'cTicket type') {
      settickettypeid(ar);
      settickettypeName(ar);
      dispatch(storeCapacityticketTypeids(ar))
      setshowtickettype('d-none');
      settickettypeinputText("");
    } else if (e.target.value === 'cTask category') {
      settaskcategoryid(ar);
      settaskcategoryName(ar);
      dispatch(storeCapacitytaskCategoryids(ar))
      setshowtaskcategory('d-none');
      settaskcategoryinputText("");
    } else if (e.target.value === 'cContract category') {
      setcontractcategoryid(ar);
      setcontractcategoryName(ar);
      dispatch(storeCapacitycontractcategoryids(ar))
      setshowcontractcategory('d-none');
      setcontractcategoryinputText("");
    } else if (e.target.value === 'cSkills') {
      setskillinputText("");
      setshowskill('d-none');
      setSkillid(ar);
      setSkillName(ar);
      dispatch(storeCapacityskillids(ar))
      setskillinputText("");
    } else if (e.target.value === 'cLineOfBusiness') {
      setlineOfBusinessinputText("");
      setshowlineOfBusiness('d-none');
      setlineOfBusinessid(ar);
      setlineOfBusinessName(ar);
      dispatch(storeCapacitylineOfBusinessIds(ar))
      setlineOfBusinessinputText("");
    }else {
      clearAllFilter(true)
    }
  }

  const clearAllFilter = (value:any) => {
    dispatch(setcallApifortodofilter(value))
    let ar: any = [];
    setProjectid([]);
    setProjectName([]);
    dispatch(storeCapacityProjectIds(ar))
    setshowproject('d-none');
    setprojectinputText("");

    setRoleid([]);
    setRoleName([]);
    dispatch(storeCapacityRoleIds(ar))
    setshowrole('d-none');
    setroleinputText("");

    setshowpeople('d-none');
    setPeopletid(ar);
    setPeopletName(ar)
    dispatch(storeCapacityPeopleIds(ar))
    setpeopleinputText("");

    setDepartmentid(ar);
    setDepartmentName(ar);
    dispatch(storeCapacityDepartmentIds(ar))
    setshowdepartment('d-none');
    setdepartmentinputText("");

    setCompanyid(ar);
    setCompanyName(ar)
    dispatch(storeCapacityCompanyIds(ar))
    setshowcompany('d-none');
    setcompanyinputText("");

    setprojectleadid(ar);
    setprojectleadName(ar)
    dispatch(storeCapacityprojectLeadsids(ar))
    setshowprojectlead('d-none');
    setprojectleadinputText("");

    setqueuesid(ar);
    setqueuesName(ar)
    dispatch(storeCapacityqueuesids(ar))
    setshowqueues('d-none');
    setqueuesinputText("");

    setprioritiesid(ar);
    setprioritiesName(ar)
    dispatch(storeCapacityprioritiesids(ar))
    setshowpriorities('d-none');
    setprioritiesinputText("");

    setticketcategoryid(ar);
    setticketcategoryName(ar)
    dispatch(storeCapacityticketCategoryids(ar))
    setshowticketcategory('d-none');
    setticketcategoryinputText("");

    settickettypeid(ar);
    settickettypeName(ar)
    dispatch(storeCapacityticketTypeids(ar))
    setshowtickettype('d-none');
    settickettypeinputText("");

    settaskcategoryid(ar);
    settaskcategoryName(ar)
    dispatch(storeCapacitytaskCategoryids(ar))
    setshowtaskcategory('d-none');
    settaskcategoryinputText("");

    setslasid(ar);
    setslasName(ar)
    dispatch(storeCapacityslasids(ar))
    setshowslas('d-none');
    setslasinputText("");

    setcontractcategoryid(ar);
    setcontractcategoryName(ar)
    dispatch(storeCapacitycontractcategoryids(ar))
    setshowcontractcategory('d-none');
    setcontractcategoryinputText("");

    setSkillid(ar);
    setSkillName(ar);
    dispatch(storeCapacityskillids(ar));
    setshowskill('d-none');
    setskillinputText("");

    setlineOfBusinessid(ar);
    setlineOfBusinessName(ar);
    dispatch(storeCapacitylineOfBusinessIds(ar))
    setshowlineOfBusiness('d-none');
    setlineOfBusinessinputText("");
  }

  const storeFilterValue = (value: any) => {
    if (value === 'cProject' || value === "all") {
      dispatch(storeCapacityProjectIds(Projectid))
    }
    if (value === 'cRole' || value === "all") {
      dispatch(storeCapacityRoleIds(Roleid))
    }
    if (value === 'cPeople' || value === "all") {
      dispatch(storeCapacityPeopleIds(Peopletid))
    }
    if (value === 'cCompany' || value === "all") {
      dispatch(storeCapacityCompanyIds(Companyid))
    }
    if (value === 'cDepartment' || value === "all") {
      dispatch(storeCapacityDepartmentIds(Departmentid))
    }
    if (value === 'cprojectlead' || value === "all") {
      dispatch(storeCapacityprojectLeadsids(projectleadid))
    }
    if (value === 'cqueues' || value === "all") {
      dispatch(storeCapacityqueuesids(queuesid))
    }
    if (value === 'cPriorities' || value === "all") {
      dispatch(storeCapacityprioritiesids(prioritiesid))
    }
    if (value === 'cTicket type' || value === "all") {
      dispatch(storeCapacityticketTypeids(tickettypeid))
    }
    if (value === 'cTicket category' || value === "all") {
      dispatch(storeCapacityticketCategoryids(ticketcategoryid))
    }
    if (value === 'cTask category' || value === "all") {
      dispatch(storeCapacitytaskCategoryids(taskcategoryid))
    }
    if (value === 'cSLAs' || value === "all") {
      dispatch(storeCapacityslasids(slasid))
    }
    if (value === 'cContract category' || value === "all") {
      dispatch(storeCapacitycontractcategoryids(contractcategoryid))
    }
    if (value === 'cSkills' || value === "all") {
      dispatch(storeCapacityskillids(Skillid))
    }
    if (value === 'cLineOfBusiness' || value === "all") {
      dispatch(storeCapacitylineOfBusinessIds(lineOfBusinessid))
    }
  }

  const resetSelectedItems = (value: any) => {
      let resetArray = ['cProject', 'cRole' , 'cPeople', 'cCompany', 'cDepartment', 'cprojectlead', 'cqueues', 'cPriorities', 'cTicket type', 'cTicket category', 'cTask category', 'cSLAs', 'cContract category', 'cSkills', 'cLineOfBusiness'];
      if(resetArray.includes('cProject')){
        setProjectid(count.project_capacity_ids)
      }
      if(resetArray.includes('cRole')){
        setRoleid(count.role_capacity_ids)
      }
      if(resetArray.includes('cPeople')){
        setPeopletid(count.people_capacity_ids)
      }
      if(resetArray.includes('cCompany')){
        setCompanyid(count.company_capacity_ids)
      }
      if(resetArray.includes('cDepartment')){
        setDepartmentid(count.department_capacity_ids)
      }
      if(resetArray.includes('cprojectlead')){
        setprojectleadid(count.project_leads_capacity_ids)
      }
      if(resetArray.includes('cqueues')){
        setqueuesid(count.queues_capacity_ids)
      }
      if(resetArray.includes('cPriorities')){
        setprioritiesid(count.priorities_capacity_ids)
      }
      if(resetArray.includes('cTicket type')){
        settickettypeid(count.ticketType_capacity_ids)
      }
      if(resetArray.includes('cTicket category')){
        setticketcategoryid(count.ticketCategory_capacity_ids)
      }
      if(resetArray.includes('cTask category')){
        settaskcategoryid(count.taskCategory_capacity_ids)
      }
      if(resetArray.includes('cSLAs')){
        setslasid(count.slas_capacity_ids)
      }
      if(resetArray.includes('cContract category')){
        setcontractcategoryid(count.contract_category_ids)
      }
      if(resetArray.includes('cSkills')){
        setSkillid(count.skill_capacity_ids)
      }
      if(resetArray.includes('cLineOfBusiness')){
        setlineOfBusinessid(count.lineOfBusiness_capacity_ids)
      }
  }

  const ChangeFilter = (e: any) => {
    dispatch(setcallApifortodofilter(true))
    if (e.target.getAttribute('data-name') === 'project') {
      setshowproject('');
      setcurrentClick('Project');
      toggleDropdown("cProject")
    } else if (e.target.getAttribute('data-name') === 'role') {
      setshowrole('');
      setcurrentClick('Role');
      toggleDropdown("cRole")
    } else if (e.target.getAttribute('data-name') === 'people') {
      setshowpeople('');
      setcurrentClick('People');
      toggleDropdown("cPeople")
    } else if (e.target.getAttribute('data-name') === 'department') {
      setshowdepartment('');
      setcurrentClick('Department');
      toggleDropdown("cDepartment")
    } else if (e.target.getAttribute('data-name') === 'projectlead') {
      setshowprojectlead('');
      setcurrentClick('projectlead');
      toggleDropdown("cprojectlead")
    } else if (e.target.getAttribute('data-name') === 'queues') {
      setshowqueues('');
      setcurrentClick('queues');
      toggleDropdown("cqueues")
    } else if (e.target.getAttribute('data-name') === 'priorities') {
      setshowpriorities('');
      setcurrentClick('Priorities');
      toggleDropdown("cPriorities")
    } else if (e.target.getAttribute('data-name') === 'tickettype') {
      setshowtickettype('');
      setcurrentClick('Ticket type');
      toggleDropdown("cTicket type")
    } else if (e.target.getAttribute('data-name') === 'ticketcategory') {
      setshowticketcategory('');
      setcurrentClick('Ticket category');
      toggleDropdown("cTicket category")
    } else if (e.target.getAttribute('data-name') === 'taskcategory') {
      setshowtaskcategory('');
      setcurrentClick('Task category');
      toggleDropdown("cTask category")
    } else if (e.target.getAttribute('data-name') === 'slas') {
      setshowslas('');
      setcurrentClick('SLAs');
      toggleDropdown("cSLAs")
    } else if (e.target.getAttribute('data-name') === 'contractcategory') {
      setshowcontractcategory('');
      setcurrentClick('Contract category');
      toggleDropdown("cContract category")
    } else if (e.target.getAttribute('data-name') === 'Skills') {
      setshowskill('');
      setcurrentClick('Skills');
      toggleDropdown("cSkills")
    } else if (e.target.getAttribute('data-name') === 'LineOfBusiness') {
      setshowlineOfBusiness('');
      setcurrentClick('LineOfBusiness');
      toggleDropdown("cLineOfBusiness")
    } else {
      setshowcompany('');
      setcurrentClick('Company');
      toggleDropdown("cCompany")
    }
  }

  let inputHandler = (e: any) => {
    //convert input text to lower case

    var lowerCase = e.target.value.toLowerCase();
    if (e.target.id === 'cPeople-csearch') {
      setpeopleinputText(lowerCase);
    } else if (e.target.id === 'cCompany-csearch') {
      setcompanyinputText(lowerCase);
    } else if (e.target.id === 'cDepartment-csearch') {
      setdepartmentinputText(lowerCase);
    } else if (e.target.id === 'cProject-csearch') {
      setprojectinputText(lowerCase);
    } else if (e.target.id === 'cRole-csearch') {
      setroleinputText(lowerCase);
    } else if (e.target.id === 'cprojectlead-csearch') {
      setprojectleadinputText(lowerCase);
    } else if (e.target.id === 'cqueues-csearch') {
      setqueuesinputText(lowerCase);
    } else if (e.target.id === 'cPriorities-csearch') {
      setprioritiesinputText(lowerCase);
    } else if (e.target.id === 'cTicket type-csearch') {
      settickettypeinputText(lowerCase);
    } else if (e.target.id === 'cTicket category-csearch') {
      setticketcategoryinputText(lowerCase);
    } else if (e.target.id === 'cTask category-csearch') {
      settaskcategoryinputText(lowerCase);
    } else if (e.target.id === 'cSLAs-csearch') {
      setslasinputText(lowerCase);
    } else if (e.target.id === 'cContract category-csearch') {
      setcontractcategoryinputText(lowerCase);
    } else if (e.target.id === 'cSkills-csearch') {
      setskillinputText(lowerCase);
    } else if (e.target.id === 'cLineOfBusiness-csearch') {
      setlineOfBusinessinputText(lowerCase);
    }
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    const parseValue = (val: any) => isNaN(val) ? val : parseInt(val);
    dispatch(setcallApifortodofilter(true))
    if (e.target.getAttribute('data-id') === 'cProject') {
      if (e.target.checked) {
        setProjectid((Projectid: any) => [...Projectid, parseInt(e.target.value)]);
      } else {
        setProjectid(Projectid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    } else if (e.target.getAttribute('data-id') === 'cRole') {
      if (e.target.checked) {
        setRoleid((Roleid: any) => [...Roleid, parseInt(e.target.value)]);
      } else {
        setRoleid(Roleid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    } else if (e.target.getAttribute('data-id') === 'cPeople') {
      if (e.target.checked) {
        setPeopletid((Peopletid: any) => [...Peopletid, parseValue(value)]);
      } else {
        setPeopletid(Peopletid.filter((item: any) => item !== parseValue(value)));
      }
    } else if (e.target.getAttribute('data-id') === 'cCompany') {
      if (e.target.checked) {
        setCompanyid((Companyid: any) => [...Companyid, parseInt(e.target.value)]);
      } else {
        setCompanyid(Companyid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    } else if (e.target.getAttribute('data-id') === 'cDepartment') {
      if (e.target.checked) {
        setDepartmentid((Departmentid: any) => [...Departmentid, parseInt(e.target.value)]);
      } else {
        setDepartmentid(Departmentid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    } else if (e.target.getAttribute('data-id') === 'cprojectlead') {
      if (e.target.checked) {
        setprojectleadid((projectleadid: any) => [...projectleadid, parseInt(e.target.value)]);
      } else {
        setprojectleadid(projectleadid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    } else if (e.target.getAttribute('data-id') === 'cqueues') {
      if (e.target.checked) {
        setqueuesid((queuesid: any) => [...queuesid, parseInt(e.target.value)]);
      } else {
        setqueuesid(queuesid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    } else if (e.target.getAttribute('data-id') === 'cPriorities') {
      if (e.target.checked) {
        setprioritiesid((prioritiesid: any) => [...prioritiesid, parseInt(e.target.value)]);
      } else {
        setprioritiesid(prioritiesid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    } else if (e.target.getAttribute('data-id') === 'cTicket type') {
      if (e.target.checked) {
        settickettypeid((tickettypeid: any) => [...tickettypeid, parseInt(e.target.value)]);
      } else {
        settickettypeid(tickettypeid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    } else if (e.target.getAttribute('data-id') === 'cTicket category') {
      if (e.target.checked) {
        setticketcategoryid((ticketcategoryid: any) => [...ticketcategoryid, parseInt(e.target.value)]);
      } else {
        setticketcategoryid(ticketcategoryid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    } else if (e.target.getAttribute('data-id') === 'cTask category') {
      if (e.target.checked) {
        settaskcategoryid((taskcategoryid: any) => [...taskcategoryid, parseInt(e.target.value)]);
      } else {
        settaskcategoryid(taskcategoryid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    } else if (e.target.getAttribute('data-id') === 'cSLAs') {
      if (e.target.checked) {
        setslasid((slasid: any) => [...slasid, parseInt(e.target.value)]);
      } else {
        setslasid(slasid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    } else if (e.target.getAttribute('data-id') === 'cContract category') {
      if (e.target.checked) {
        setcontractcategoryid((contractcategoryid: any) => [...contractcategoryid, parseInt(e.target.value)]);
      } else {
        setcontractcategoryid(contractcategoryid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    } else if (e.target.getAttribute('data-id') === 'cSkills') {
      if (e.target.checked) {
        setSkillid((Skillid: any) => [...Skillid, parseInt(e.target.value)]);
      } else {
        setSkillid(Skillid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    } else if (e.target.getAttribute('data-id') === 'cLineOfBusiness') {
      if (e.target.checked) {
        setlineOfBusinessid((lineOfBusinessid: any) => [...lineOfBusinessid, parseInt(e.target.value)]);
      } else {
        setlineOfBusinessid(lineOfBusinessid.filter((item: any) => item !== parseInt(e.target.value)));
      }
    }
  }

  const selectAll = (e: any) => {
    dispatch(setcallApifortodofilter(true))
    if (e.target.id === 'cProject') {
      if (e.target.checked) {
        const selectallid = filterCapacityArray?.projects?.filter((twork: any) => (twork.isActive === true && twork.isCompleted === false))?.map((x: any) => parseInt(x.id));
        setProjectid(selectallid);
      } else {
        setProjectid([]);
      }
    } else if (e.target.id === 'cRole') {
      if (e.target.checked) {
        const selectallid = filterCapacityArray?.roles?.filter((twork: any) => (twork.isActive === true))?.map((x: any) => parseInt(x.id));
        setRoleid(selectallid);
      } else {
        setRoleid([]);
      }
    } else if (e.target.id === 'cPeople') {
      if (e.target.checked) {
        const selectallid = filterCapacityArray?.peoples?.filter((twork: any) => (twork.isActive === true))?.map((x: any) => parseInt(x.id));
        const emptyID = 'noresource'
        const selectAllResources = [...selectallid, emptyID]
        setPeopletid(selectAllResources);
      } else {
        setPeopletid([]);
      }
    } else if (e.target.id === 'cCompany') {
      if (e.target.checked) {
        const selectallid = filterCapacityArray?.companies?.filter((twork: any) => (twork.isActive === true))?.map((x: any) => parseInt(x.id));
        setCompanyid(selectallid);
      } else {
        setCompanyid([]);
      }
    } else if (e.target.id === 'cDepartment') {
      if (e.target.checked) {
        const selectallid = filterCapacityArray?.departments?.items.map((x: any) => parseInt(x.id));
        setDepartmentid(selectallid);
      } else {
        setDepartmentid([]);
      }
    } else if (e.target.id === 'cprojectlead') {
      
      if (e.target.checked) {
        const selectallid = filterCapacityArray?.peoples?.filter((twork: any) => (twork.isActive === true))?.map((x: any) => parseInt(x.id));
        setprojectleadid(selectallid);
      } else {
        setprojectleadid([]);
      }
    } else if (e.target.id === 'cPriorities') {
      if (e.target.checked) {
        const selectallid = filterCapacityArray?.priorities?.data?.filter((twork: any) => (twork.isActive === true))?.map((x: any) => parseInt(x.id));
        setprioritiesid(selectallid);
      } else {
        setprioritiesid([]);
      }
    } else if (e.target.id === 'cTicket type') {
      if (e.target.checked) {
        const selectallid = filterCapacityArray?.ticketType?.data?.filter((twork: any) => (twork.isActive === true))?.map((x: any) => parseInt(x.id));
        settickettypeid(selectallid);
      } else {
        settickettypeid([]);
      }
    } else if (e.target.id === 'cTicket category') {
      if (e.target.checked) {
        const selectallid = filterCapacityArray?.ticketCategory?.data?.filter((twork: any) => (twork.isActive === true))?.map((x: any) => parseInt(x.id));
        setticketcategoryid(selectallid);
      } else {
        setticketcategoryid([]);
      }
    } else if (e.target.id === 'cTask category') {
      if (e.target.checked) {
        const selectallid = filterCapacityArray?.taskCategory?.data?.filter((twork: any) => (twork.isActive === true))?.map((x: any) => parseInt(x.id));
        settaskcategoryid(selectallid);
      } else {
        settaskcategoryid([]);
      }
    } else if (e.target.id === 'cSLAs') {
      if (e.target.checked) {
        const selectallid = filterCapacityArray?.slas?.data?.filter((twork: any) => (twork.isActive === true))?.map((x: any) => parseInt(x.id));
        setslasid(selectallid);
      } else {
        setslasid([]);
      }
    } else if (e.target.id === 'cqueues') {
      if (e.target.checked) {
        const selectallid = filterCapacityArray?.queues?.data?.filter((twork: any) => (twork.isActive === true))?.map((x: any) => parseInt(x.id));
        setqueuesid(selectallid);
      } else {
        setqueuesid([]);
      }
    } else if (e.target.id === 'cContract category') {
      if (e.target.checked) {
        const selectallid = filterCapacityArray?.contractCategory?.data?.filter((twork: any) => (twork.isActive === true))?.map((x: any) => parseInt(x.id));
        setcontractcategoryid(selectallid);
      } else {
        setcontractcategoryid([]);
      }
    } else if (e.target.id === 'cSkills') {
      if (e.target.checked) {
        const selectallid = filterCapacityArray?.skills?.data?.filter((twork: any) => (twork.isActive === true))?.map((x: any) => parseInt(x.id));
        setSkillid(selectallid);
      } else {
        setSkillid([]);
      }
    } else if (e.target.id === 'cLineOfBusiness') {
      if (e.target.checked) {
        const selectallid = filterCapacityArray?.lineOfBusiness?.data?.filter((twork: any) => (twork.isActive === true)).map((x: any) => parseInt(x.id));
        setlineOfBusinessid(selectallid);
      } else {
        setlineOfBusinessid([]);
      }
    }
  };
  
  const redux_data = useAppSelector((state) => state.counter)
  useEffect(() => {
    if (redux_data?.clear_all_filter) {
      dispatch(storeClearAllFilter(false))
      clearAllFilter(true)
    }
  }, [redux_data?.clear_all_filter])

  useEffect(() => {
    if(!filterCapacityArray?.isfetchlayoutandfilters && !filterCapacityArray?.isPeopleFetch && !filterCapacityArray?.isDepartmentFetch && !filterCapacityArray?.isCompanyFetch && !filterCapacityArray?.isProjectFetch && !filterCapacityArray?.isSkillFetch && !filterCapacityArray?.isRoleFetch && !filterCapacityArray?.isQueuesFetch && !filterCapacityArray?.isTicketCategoryFetch && !filterCapacityArray?.isTicketCategoryFetch && !filterCapacityArray?.isTicketTypeFetch && !filterCapacityArray?.isTaskCategoryFetch && !filterCapacityArray?.isTaskCategoryFetch && !filterCapacityArray?.isSlasFetch && !filterCapacityArray?.isContractCategoryFetch){
      let data = filterCapacityArray?.layoutandfilters?.task_list_filter && Object.keys(filterCapacityArray.layoutandfilters.task_list_filter).length > 0
      ? filterCapacityArray.layoutandfilters.task_list_filter
      : {};
      if(Object.keys(data)?.length === 0){
        clearAllFilter(false);
      }
      if(data?.companies?.length >0){
        let ids1 = filterCapacityArray?.companies?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const filteredcompany = data?.companies?.filter((id:any) => ids1?.includes(id));
        setCompanyid(filteredcompany)
        dispatch(storeCapacityCompanyIds(filteredcompany))
        setshowcompany('');
        //setcurrentClick('Company');
      }
      if(data?.resources?.length >0){
        let ids2 = filterCapacityArray?.peoples?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const emptyID = 'noresource'
        const allIds2 = [...ids2, emptyID]
        const filteredresource = data?.resources?.filter((id:any) => allIds2?.includes(id));
        setPeopletid(filteredresource)
        dispatch(storeCapacityPeopleIds(filteredresource))
        setshowpeople('');
        //setcurrentClick('People');
      }
      if(data?.departments?.length >0){
        let ids3 = filterCapacityArray?.departments?.items?.map((item1:any) => parseInt(item1?.id));
        const filtereddepartments = data?.departments?.filter((id:any) => ids3?.includes(id));
        setDepartmentid(filtereddepartments)
        dispatch(storeCapacityDepartmentIds(filtereddepartments))
        setshowdepartment('');
        //setcurrentClick('Department');
      }
      if(data?.projects?.length >0){
        let ids4 = filterCapacityArray?.projects?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const filteredprojects = data?.projects?.filter((id:any) => ids4?.includes(id));
        setProjectid(filteredprojects)
        dispatch(storeCapacityProjectIds(filteredprojects))
        setshowproject('');
      }
      if(data?.roles?.length >0){
        let ids5 = filterCapacityArray?.roles?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const filteredroles = data?.roles?.filter((id:any) => ids5?.includes(id));
        setRoleid(filteredroles)
        dispatch(storeCapacityRoleIds(filteredroles))
        setshowrole('');
      }
      if(data?.queues?.length >0){
        let ids6 = filterCapacityArray?.queues?.data?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const filteredqueues_ids = data?.queues?.filter((id:any) => ids6?.includes(id));
        setqueuesid(filteredqueues_ids)
        dispatch(storeCapacityqueuesids(filteredqueues_ids))
        setshowqueues('');
      }
      if(data?.skills?.length >0){
        let ids8 = filterCapacityArray?.skills?.data?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const filteredskills = data?.skills?.filter((id:any) => ids8?.includes(id));
        setSkillid(filteredskills)
        dispatch(storeCapacityskillids(filteredskills))
        setshowskill('');
      }
      if(data?.contract_category?.length >0){
        let ids9 = filterCapacityArray?.contractCategory?.data?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const filteredcontract_category = data?.contract_category?.filter((id:any) => ids9?.includes(id));
        setcontractcategoryid(filteredcontract_category)
        dispatch(storeCapacitycontractcategoryids(filteredcontract_category))
        setshowcontractcategory('');
      }
      if(data?.priority?.length >0){
        let ids10 = filterCapacityArray?.priorities?.data?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const filteredpriority = data?.priority?.filter((id:any) => ids10?.includes(id));
        setprioritiesid(filteredpriority)
        dispatch(storeCapacityprioritiesids(filteredpriority))
        setshowpriorities('');
      }
      if(data?.project_leads?.length >0){
        let ids11 = filterCapacityArray?.peoples?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const filteredproject_leads = data?.project_leads?.filter((id:any) => ids11?.includes(id));
        setprojectleadid(filteredproject_leads)
        dispatch(storeCapacityprojectLeadsids(filteredproject_leads))
        setshowprojectlead('');
      }
      if(data?.sla?.length >0){
        let ids12 = filterCapacityArray?.slas?.data?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const filteredsla = data?.sla?.filter((id:any) => ids12?.includes(id));
        setslasid(filteredsla)
        dispatch(storeCapacityslasids(filteredsla))
        setshowslas('');
      }
      if(data?.task_categories?.length >0){
        let ids13 = filterCapacityArray?.taskCategory?.data?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const filteredtask_categories = data?.task_categories?.filter((id:any) => ids13?.includes(id));
        settaskcategoryid(filteredtask_categories)
        dispatch(storeCapacitytaskCategoryids(filteredtask_categories))
        setshowtaskcategory('');
      }
      if(data?.ticket_categories?.length >0){
        let ids14 = filterCapacityArray?.ticketCategory?.data?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const filteredticket_categories = data?.ticket_categories?.filter((id:any) => ids14?.includes(id));
        setticketcategoryid(filteredticket_categories)
        dispatch(storeCapacityticketCategoryids(filteredticket_categories))
        setshowticketcategory('');
      }
      if(data?.ticket_types?.length >0){
        let ids15 = filterCapacityArray?.ticketType?.data?.filter((twork: any) => (twork.isActive === true))?.map((item1:any) => parseInt(item1?.id));
        const filteredticket_types = data?.ticket_types?.filter((id:any) => ids15?.includes(id));
        settickettypeid(filteredticket_types)
        dispatch(storeCapacityticketTypeids(filteredticket_types))
        setshowtickettype('');
      }
      if(data?.lineOfBusiness?.length >0){
        setlineOfBusinessid(data?.lineOfBusiness)
        dispatch(storeCapacitylineOfBusinessIds(data?.lineOfBusiness))
        setshowlineOfBusiness('');
      }
    }
  }, [filterCapacityArray?.isfetchlayoutandfilters,filterCapacityArray?.isPeopleFetch,filterCapacityArray?.isDepartmentFetch, filterCapacityArray?.isCompanyFetch, filterCapacityArray?.isProjectFetch,filterCapacityArray?.isSkillFetch, filterCapacityArray?.isRoleFetch, filterCapacityArray?.isQueuesFetch, filterCapacityArray?.isTicketCategoryFetch,filterCapacityArray?.isTicketCategoryFetch, filterCapacityArray?.isTicketTypeFetch, filterCapacityArray?.isTaskCategoryFetch, filterCapacityArray?.isTaskCategoryFetch,filterCapacityArray?.isSlasFetch,filterCapacityArray?.isContractCategoryFetch ]);

  useEffect(() => {
    resetSelectedItems(openDropdown)
  },[openDropdown])

  useEffect(() => {
    if(!filterCapacityArray?.isfetchlayoutandfilters && !filterCapacityArray?.isPeopleFetch && !filterCapacityArray?.isDepartmentFetch && !filterCapacityArray?.isCompanyFetch && !filterCapacityArray?.isProjectFetch && !filterCapacityArray?.isSkillFetch && !filterCapacityArray?.isRoleFetch && !filterCapacityArray?.isQueuesFetch && !filterCapacityArray?.isTicketCategoryFetch && !filterCapacityArray?.isTicketCategoryFetch && !filterCapacityArray?.isTicketTypeFetch && !filterCapacityArray?.isTaskCategoryFetch && !filterCapacityArray?.isTaskCategoryFetch && !filterCapacityArray?.isSlasFetch && !filterCapacityArray?.isContractCategoryFetch){

      const companyNameArray = filterCapacityArray.companies
            .filter((item: { id: any; }) => Companyid.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setCompanyName(companyNameArray)

      const resourceNameArray = filterCapacityArray?.peoples?.filter((item: { id: any; }) => Peopletid.includes(item.id)).map((item: { name: any; }) => item.name)
      if(Peopletid.includes("noresource")){
        resourceNameArray.push("Empty")
      }
      setPeopletName(resourceNameArray) 
      
      const departMentNameArray = filterCapacityArray.departments.items
          .filter((item: { id: any; }) => Departmentid.includes(item.id))
          .map((item: { name: any; }) => item.name)
      setDepartmentName(departMentNameArray)

      const projectNameArray = filterCapacityArray.projects
          .filter((item: { id: any; }) => Projectid.includes(item.id))
          .map((item: { name: any; }) => item.name)
      setProjectName(projectNameArray)
    
      const roleNameArray = filterCapacityArray.roles
          .filter((item: { id: any; }) => Roleid.includes(item.id))
          .map((item: { name: any; }) => item.name)
      setRoleName(roleNameArray)
      
      const queueNameArray = filterCapacityArray.queues.data
          .filter((item: { id: any; }) => queuesid.includes(item.id))
          .map((item: { name: any; }) => item.name)
      setqueuesName(queueNameArray)

      const skillNameArray = filterCapacityArray?.skills?.data?.filter((item: { id: any; }) => Skillid.includes(item.id)).map((item: { name: any; }) => item.name)
      setSkillName(skillNameArray)

      const contractcategoryNameArray = filterCapacityArray?.contractCategory?.data
          .filter((item: { id: any; }) => contractcategoryid.includes(item.id))
          .map((item: { name: any; }) => item.name)
      setcontractcategoryName(contractcategoryNameArray)

      const priorityNameArray = filterCapacityArray?.priorities?.data
          .filter((item: { id: any; }) => prioritiesid.includes(item.id))
          .map((item: { name: any; }) => item.name)
      setprioritiesName(priorityNameArray)

      const project_leadsNameArray = filterCapacityArray?.peoples?.filter((item: { id: any; }) => projectleadid.includes(item.id)).map((item: { name: any; }) => item.name)
      setprojectleadName(project_leadsNameArray)

      const slasNameArray = filterCapacityArray?.slas?.data?.filter((item: { id: any; }) => slasid.includes(item.id)).map((item: { name: any; }) => item.name)
      setslasName(slasNameArray)

      const task_categoriesNameArray = filterCapacityArray?.taskCategory?.data?.filter((item: { id: any; }) => taskcategoryid.includes(item.id)).map((item: { name: any; }) => item.name)
      settaskcategoryName(task_categoriesNameArray)
   
      const ticket_categoriesNameArray = filterCapacityArray?.ticketCategory?.data?.filter((item: { id: any; }) => ticketcategoryid.includes(item.id)).map((item: { name: any; }) => item.name)
      setticketcategoryName(ticket_categoriesNameArray)

      const ticket_typesNameArray = filterCapacityArray?.ticketType?.data?.filter((item: { id: any; }) => tickettypeid.includes(item.id)).map((item: { name: any; }) => item.name)
      settickettypeName(ticket_typesNameArray)
      
      const lineOfBusinessNameArray = filterCapacityArray?.lineOfBusiness?.data?.filter((item: { id: any; }) => lineOfBusinessid.includes(item.id)).map((item: { name: any; }) => item.name)
      setlineOfBusinessName(lineOfBusinessNameArray)
    
    }
  }, [Companyid, Peopletid, Departmentid,Projectid, Roleid, queuesid,Skillid,contractcategoryid, prioritiesid,projectleadid,slasid,taskcategoryid ,ticketcategoryid, tickettypeid, lineOfBusinessid]);

  return (
    <>
      <div className="dropDown d-flex align-items-center mb-2">
        <button 
            onClick={() => {
              toggleDropdown("main-planbucket-filter")
            }} 
            id={`main-planbucket-filter`}
            className="btn btn-outline btn-outline-primary fw-normal border-primary rounded-pill px-4 py-1 min-h-36px fs-14 d-flex align-items-center me-2" 
            type="button" 
            data-kt-menu-trigger="click" 
            data-kt-menu-attach="parent" 
            data-kt-menu-placement="bottom-start" 
            data-kt-menu-flip="bottom">
          <KTSVG path="/media/icons/duotune/new-icons/filter.svg" className='me-2' stylecode={{ color: "inherit" }} svgClassName="w-16px h-14px" />
          Filter
        </button>
        {openDropdown === "main-planbucket-filter" &&
        (<div className="menu menu-sub menu-sub-dropdown bg-white px-3 py-5 min-w-130px fs-14 show position-absolute top-100 start-0" data-kt-menu="true">
          <a onClick={ChangeFilter} data-name="people" className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" href="#">
            <KTSVG path="/media/icons/duotune/new-icons/resources.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Resources</a>
          <a onClick={ChangeFilter} data-name="department" className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" href="#">
            <KTSVG path="/media/icons/duotune/extra/mouse-square.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Department</a>
          <a onClick={ChangeFilter} data-name="company" className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" href="#">
            <KTSVG path="/media/icons/duotune/new-icons/company.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Company</a>
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="project" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/project.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Project</a>
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="role" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/role.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Role</a>
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="projectlead" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/project-leads.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Project Leads</a>
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="queues" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/queues.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Queues</a>
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="priorities" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/priorities.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Priorities</a>
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="tickettype" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/ticket-type.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Ticket type</a>
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="ticketcategory" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/ticket-category.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Ticket category</a>
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="taskcategory" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/task-category.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Task category</a>
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="slas" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/slas.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />SLAs</a>
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="contractcategory" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/contract-category.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Contract category</a>
          {
            general_settings?.show_resource_skills === true &&
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="Skills" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/skills.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Skills</a>
          }
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="LineOfBusiness" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/extra/note.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Line of business</a>
        </div>)
        }
      </div>

      <CapacityDropDown imgpath="/media/icons/duotune/extra/profile-user-2.svg" inputHandler={inputHandler} inputText={peopleinputText} currentClick={currentClick} checked_ids={Peopletid} title="cPeople" display_title="People" data={filterCapacityArray?.peoples?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isPeopleFetch} showdropdown={showpeople} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={PeopletName} selectAll={selectAll} tooltipTitle='Filter resources on name.' isOpen={openDropdown === 'cPeople'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/extra/mouse-square.svg" inputHandler={inputHandler} inputText={departmentinputText} currentClick={currentClick} checked_ids={Departmentid} title="cDepartment" display_title="Department" data={filterCapacityArray?.departments?.items} isLoading={filterCapacityArray?.isDepartmentFetch} showdropdown={showdepartment} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={DepartmentName} selectAll={selectAll} tooltipTitle='Filter resources on their default department.' isOpen={openDropdown === 'cDepartment'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/extra/profile-user-1.svg" inputHandler={inputHandler} inputText={companyinputText} currentClick={currentClick} checked_ids={Companyid} title="cCompany" display_title="Company" data={filterCapacityArray?.companies?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isCompanyFetch} showdropdown={showcompany} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={CompanyName} selectAll={selectAll} tooltipTitle='Filter resources on their company.' isOpen={openDropdown === 'cCompany'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/project.svg" inputHandler={inputHandler} inputText={projectinputText} currentClick={currentClick} checked_ids={Projectid} title="cProject" display_title="Project" data={filterCapacityArray?.projects?.filter((twork: any) => (twork.isActive === true && twork.isCompleted === false))} isLoading={filterCapacityArray?.isProjectFetching} showdropdown={showproject} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={ProjectName} selectAll={selectAll} tooltipTitle='Filter resources on their project.' isOpen={openDropdown === 'cProject'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/role.svg" inputHandler={inputHandler} inputText={roleinputText} currentClick={currentClick} checked_ids={Roleid} title="cRole" display_title="Role" data={filterCapacityArray?.roles?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isRoleFetching} showdropdown={showrole} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={RoleName} selectAll={selectAll} tooltipTitle='Filter resources on their roles.' isOpen={openDropdown === 'cRole'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/project-leads.svg" inputHandler={inputHandler} inputText={projectleadinputText} currentClick={currentClick} checked_ids={projectleadid} display_title="projectlead" title="cprojectlead" data={filterCapacityArray?.peoples?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isPeopleFetch} showdropdown={showprojectlead} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={projectleadName} selectAll={selectAll} tooltipTitle='Filter data on their project leads.' isOpen={openDropdown === 'cprojectlead'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/queues.svg" inputHandler={inputHandler} inputText={queuesinputText} currentClick={currentClick} checked_ids={queuesid} title="cqueues" display_title="queues" data={filterCapacityArray?.queues?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isQueuesFetch} showdropdown={showqueues} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={queuesName} selectAll={selectAll} tooltipTitle='Filter data on their queues.' isOpen={openDropdown === 'cqueues'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/priorities.svg" inputHandler={inputHandler} inputText={prioritiesinputText} currentClick={currentClick} checked_ids={prioritiesid} title="cPriorities"  display_title="Priorities" data={filterCapacityArray?.priorities?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isPrioritiesFetch} showdropdown={showpriorities} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={prioritiesName} selectAll={selectAll} tooltipTitle='Filter data on their priorities.' isOpen={openDropdown === 'cPriorities'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/ticket-type.svg" inputHandler={inputHandler} inputText={tickettypeinputText} currentClick={currentClick} checked_ids={tickettypeid} title="cTicket type" display_title="Ticket type" data={filterCapacityArray?.ticketType?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isTicketTypeFetch} showdropdown={showtickettype} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={tickettypeName} selectAll={selectAll} tooltipTitle='Filter data on their ticket type.' isOpen={openDropdown === 'cTicket type'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/ticket-category.svg" inputHandler={inputHandler} inputText={ticketcategoryinputText} currentClick={currentClick} checked_ids={ticketcategoryid} display_title="Ticket category" title="cTicket category" data={filterCapacityArray?.ticketCategory?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isTicketCategoryFetch} showdropdown={showticketcategory} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={ticketcategoryName} selectAll={selectAll} tooltipTitle='Filter data on their ticket category.' isOpen={openDropdown === 'cTicket category'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/task-category.svg" inputHandler={inputHandler} inputText={taskcategoryinputText} currentClick={currentClick} checked_ids={taskcategoryid} display_title="Task category" title="cTask category" data={filterCapacityArray?.taskCategory?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isTaskCategoryFetch} showdropdown={showtaskcategory} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={taskcategoryName} selectAll={selectAll} tooltipTitle='Filter data on their task category.' isOpen={openDropdown === 'cTask category'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/slas.svg" inputHandler={inputHandler} inputText={slasinputText} currentClick={currentClick} checked_ids={slasid} display_title="SLAs" title="cSLAs" data={filterCapacityArray?.slas?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isSlasFetch} showdropdown={showslas} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={slasName} selectAll={selectAll} tooltipTitle='Filter data on their slas.' isOpen={openDropdown === 'cSLAs'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/contract-category.svg" inputHandler={inputHandler} inputText={contractcategoryinputText} currentClick={currentClick} checked_ids={contractcategoryid} display_title="Contract category" title="cContract category" data={filterCapacityArray?.contractCategory?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isContractCategoryFetch} showdropdown={showcontractcategory} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={contractcategoryName} selectAll={selectAll} tooltipTitle='Filter data on their contract category.' isOpen={openDropdown === 'cContract category'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>
      
      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/skills.svg" inputHandler={inputHandler} inputText={skillinputText} currentClick={currentClick} checked_ids={Skillid} title="cSkills" display_title="Skills" data={filterCapacityArray?.skills?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isSkillFetch} showdropdown={showskill} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={SkillName} selectAll={selectAll} tooltipTitle='Filter data on their Skills.' isOpen={openDropdown === 'cSkills'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/extra/note.svg" inputHandler={inputHandler} inputText={lineOfBusinessinputText} currentClick={currentClick} checked_ids={lineOfBusinessid} title="cLineOfBusiness" display_title="LineOfBusiness" data={filterCapacityArray?.lineOfBusiness?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.islineOfBusinessFetch} showdropdown={showlineOfBusiness} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={lineOfBusinessName} selectAll={selectAll} tooltipTitle='Filter data on their line of business.' isOpen={openDropdown === 'cLineOfBusiness'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>
    </>
  );
}

export default FilterCapacity
